import HomeFooterIcons from './HomeFooterIcons'

const HomeFooter = () => {
  return (
    <div id='home-footer'>
      <HomeFooterIcons />
    </div>
  )
}

export default HomeFooter
