import AboutText from './AboutText'

const About = () => {
  return (
    <div id='about'>
      <AboutText />
    </div>
  )
}

export default About
