import Slider from './Slider'

const Projects = () => {
  return (
    <div id='projects'>
      <Slider />
    </div>
  )
}

export default Projects
